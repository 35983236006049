<template>
<div class="navbox">
  <div class="itembox">
    <div :class="['item', item.id == 3 ? 'cash' : '']" @click="goUrl(item)" v-for="item in list" :key="item.id">
      <img :src="item.logo" />
      <!-- <span>{{ item.name }}</span> -->
    </div>
  </div>
  <div class="support">
    <img src="../assets/img/wh.png" />
    <span>技术支持：华有科技</span>
  </div>
</div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      list: [{
          id: 1,
          name: '信发系统',
          logo: require('../assets/nav/xf.png'),
					url: process.env.VUE_APP_LIBRARY
        },
        {
          id: 2,
          name: '组织人事',
          logo: require('../assets/nav/rs.png'),
          url: 'index',
           type:'personnel'
        },
        {
          id: 3,
          name: '收银系统',
          logo: require('../assets/nav/cash.png'),
					url: process.env.VUE_APP_CASH
        },
        {
          id: 4,
          name: '物业小区',
          logo: require('../assets/nav/wy.png'),
					url: '/index/community/commMan',
          type:'property'
        }
        ],
      userInfo: {},
    }
  },
  created() {
    this.getUserInfos()
  },
  methods: {
    ...mapActions(['getUserInfo']),
    getUserInfos() {
      if (this.$route.query.token) {
        this.getUserInfo({ token: this.$route.query.token });
        // this.$store.commit('getUserInfo', { token: this.$route.query.token })
      } else {
        let userInfo = window.localStorage.getItem('userInfo') || {};
        if (userInfo) {
          try {
            this.userInfo = JSON.parse(userInfo);
            this.userInfo.avatar = this.userInfo.headerImg;
            this.userInfo.r_name = this.userInfo.nickName;
            this.userInfo.tradeName = this.userInfo.userName
          } catch {
            this.userInfo = {};
            this.userInfo.avatar = ''
          }
        }
        this.$store.commit('localInfo', this.userInfo)
      }
    },
    goUrl(item) {
      this.$store.commit('setType',item.type)
        window.localStorage.setItem('type',item.type)
      if (item.id == 2 || item.id == 4) {
        this.$router.push(item.url);
      } else {

        window.open(item.url);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.navbox {
    padding-top: 130px;
    box-sizing: border-box;

    .itembox {
        display: flex;
        justify-content: center;
    }

    .item {
        // width: 120px;
        // height: 120px;
        // background: #2c68ff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-left: 20px;
        cursor: pointer;
        // padding: 8px;
        box-sizing: border-box;

        span {
            margin-top: 10px;
        }

        img {
            width: 180px;
            height: 200px;
            object-fit: contain;
        }
			
    }
    .item:hover{
      box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6);
      transform:translate(0,-5px);
    }

    .support {
        position: fixed;
        bottom: 79px;
        left: 50%;
        transform: translateX(-50%);
        img {
            width: 10px;
            height: 11px;
            margin: 0 5px 0 16px;
        }

        span {
            font-size: 12px;
            font-family: Microsoft YaHei;

            &:nth-child(1) {
                font-weight: 400;
                color: #666666;
            }

            &:nth-child(3) {
                font-weight: 500;
                color: #999999;
            }
        }
    }
}
</style>
